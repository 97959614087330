@import "primeng/resources/themes/aura-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

:root {
    --font-color: #525252;
    --font-color-invert: #e9e9e9;
    --menu-hover: #ffffff1a;
    --menu-highlight: #ffffff1a;
    --submenu-hover: #ffffff1a;
    --submenu-highlight: #ffffff1a;
  }

body {
    margin: 0;
    font-family: 'Kanit', sans-serif;
}

.p-component {
    font-family: 'Kanit', sans-serif;
}
  
.p-inputtext {
    font-family: 'Kanit', sans-serif;
}

.full-container {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.separator-line{
    border-bottom: 5px solid transparent;
    border-image: linear-gradient(45deg, #1E8E42 , #FFFFFF);
    border-image-slice: 1;
}

.about-section {
    background-color: #F5F5F5;
    width: 100%;
    scroll-margin-top: 60px;
}

.text-description{
    font-size: 18px;
}

.ourservice-section {
    background-color: #FFFFFF;
    width: 100%;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 8px 8px -5px;
    border-radius: 12px;
}

.grid-zero {
    padding: 0px;
    margin: 0px;
}

.copyright {
    height: 100px;
    background: #1e8e42;
    color: white;
    text-align: center;
}

.splide:not(.is-overflow) .splide__arrows {
    display: none;
}
  
.splide:not( .is-overflow ) .splide__list {
    justify-content: center;
}
  
.splide:not( .is-overflow ) .splide__slide:last-child {
    margin: 0 !important;
}

.service-name {
    color: #1E8E42;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 150px;
    text-align: center;
    z-index: 9999;
}

.facility-name {
    color: #1E8E42;
    text-align: center;
}

.location, .tel, .start-price {
    font-size: 18px;
    color: #262523;
}

.content-roomdetail {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
}

.layout-wrapper .layout-topbar .p-dialog .p-dialog-header {
    border-bottom: none;
    background: none;
    color: #495057;
    padding: 0rem;
}

.top-bar {
    width:100%;
    position: fixed;
    top:0;
    z-index: 1500;
    right: 0;
    background-color: #2D2D2D;
}

.layout-sidebar {
    left: 0;
}

.layout-menu-container {
    margin-top: 62px;
}

.layout-wrapper.layout-menu-static.layout-menu-static-inactive .layout-sidebar {
    left: -250px;
}

.layout-menu-container .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header>a,
.layout-menu-container .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header>a {
  color: white;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: white;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: white;
}

.layout-menu-container .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link-active {
  background-color: var(--submenu-highlight);
  color: white;
}

.layout-menu-container .p-panelmenu .p-panelmenu-header>a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8f9fa;
  box-shadow: none;
}

.layout-menu-container .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #eaeaea;
  box-shadow: none;
}

.top-bar {
    width:100%;
    position: fixed;
    top:0;
    z-index: 1500;
    right: 0;
    background-color: #2D2D2D;
}

.icon-color{
    color: #1E8E42;
}

p {
    margin-top: 0px;
}

.bt-policy{
    background-color: #1e8e42;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 3px 3px 5px -2px #000000;
}
  


.a-open{
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline;
    color: #262523;
  }

  .dialog-cus{
    background: #1E8E42 !important;
    border-color: #1E8E42 !important;
  }

  .dialog-skip{
    background: #7f7f7f !important;
    border-color: #7f7f7f !important;
  }

  .p-button-success {
    background: #1e8e42;
    border: #1e8e42;
  }

  .bt-viewmap{
    font-size: 16px;
    font-weight: bold;
    background: #FFFFFF;
    color: #1e8e42;
    box-shadow: 3px 3px 5px -2px #000000;
}
  
